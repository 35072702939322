import React from 'react'
import ImageGallery from '@/src/components/views/productPage/ImageGallery'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function BeerCanChickenSection2({ settings }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const soldOut = settings?.soldOut 
  const images = settings?.images

  if (soldOut) {
    return null;
  }
  return (
    <Box margin={2}>
      <ImageGallery
        height={isMd ? '600px' : '300px'}
        images={images}
        showThumbs={false}
      />
    </Box>
  )
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Link from 'next/link'
import { Mixpanel } from '@/utils/mixpanel'
import Image from 'next/image'
import { makeProductUrl } from '@/src/utils/urlGenerator'
import { Button } from '@mui/material'

const attributes = {
  'Image above text': {
    diretion: 'column',
    maxWidthText: '100%',
    mediaFirst: true,
  },
  'Text above image': {
    diretion: 'column',
    maxWidthText: '100%',
    mediaFirst: false,
  },
  'Image on the right': {
    diretion: 'row',
    maxWidthText: '100%',
    mediaFirst: false,
  },
  'Image on the left': {
    diretion: 'row',
    maxWidthText: '100%',
    mediaFirst: true,
  }
} 
const DEFAULT_ATTRIBUTES = 'Image above text'
const ProductCardRefresh = ({
  product,
  clickable = true,
  locRef,
  height = '500px',
  card,
}) => {
  const theme = useTheme()
  const mainColor = theme.palette.primary.main

  const { diretion, maxWidthText, mediaFirst } =
    attributes[card] || attributes[DEFAULT_ATTRIBUTES]

  const productUrl = makeProductUrl({ product, ref: locRef })

  const cardImage = product?.content?.main_image
  const cardMedia = cardImage && (
    <CardMedia
      title={product.content?.title}
      sx={{
        height: '80%',
        position: 'relative',
        // flexGrow: 2,
        width: '40%',
      }}
    >
      <Image
        layout="fill"
        src={cardImage}
        alt="product image"
        objectFit="contain"
      />
    </CardMedia>
  )
  const cardContent = (
    <Box
      component={CardContent}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      sx={{
        maxWidth: maxWidthText,
      }}
    >
      <Typography
        variant="h5"
        color={mainColor}
        align={'center'}
        sx={{ fontWeight: 'bold' }}
      >
        {product.content?.title}
      </Typography>
      <Box
        component={Typography}
        variant={'h6'}
        fontWeight={500}
        align={'left'}
      >
        {product?.content?.short_description}
      </Box>
      <Button variant="contained" size="large" color="secondary">
        Shop now
      </Button>
    </Box>
  )
  return (
    <Link href={clickable ? productUrl : ''}>
      <a>
        <Box
          component={Card}
          width={'100%'}
          height={height}
          borderRadius={'1.8rem'}
          border="0.1rem"
          position={'relative'}
          display={'flex'}
          flexDirection={diretion}
          alignItems={'center'}
          justifyContent={'center'}
          padding={4}
          gap={5}
          sx={{
            shadowVerticalOffset: '0.4rem',
            shadowBlurRadius: '0.5rem',
          }}
          onClick={() =>
            Mixpanel.track('Prodcut card click', {
              product: product.key,
              locRef,
            })
          }
          // display={'flex'}
          // flexDirection={'column'}
          // justifyContent={'flex-start'}
        >
          {mediaFirst ? (
            <>
              {cardMedia}
              {cardContent}
            </>
          ) : (
            <>
              {cardContent}
              {cardMedia}
            </>
          )}
        </Box>
      </a>
    </Link>
  )
}


export default ProductCardRefresh

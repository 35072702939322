import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Image from 'next/image'

export default function MediaWithText({ settings }) {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const title = settings?.title
  const image = settings?.image
  const imageRight = settings?.image_right
  const bodyText = settings?.body_text
  const bodyTextAlign = settings?.body_text_align
  const backgroundImage = settings?.background_image
  const height = settings?.height

  return (
    <Box
      height={height || 'auto'}
      width={'100%'}
      padding={4}
      sx={
        backgroundImage && {
          backgroundColor: '#013C75',
          backgroundSize: 'cover',
          // backgroundAttachment: 'fixed',
          backgroundImage: `url("${backgroundImage}")`,
        }
      }
    >
      {title && (
        <Typography
          variant={'h4'}
          gutterBottom
          sx={{ fontWeight: 400 }}
          align="center"
        >
          {title}
        </Typography>
      )}

      <Box
        display="flex"
        flexDirection={isMd ? 'row' : 'column'}
        width={'100%'}
        gap={3}
      >
        <Box width={isMd ? '50%' : null}>
          <Image
            width={isMd ? '600px' : '300px'}
            height={isMd ? '600px' : '300px'}
            objectFit="contain"
            src={image}
          />
        </Box>

        {bodyText && (
          <Box display="flex" width={isMd ? '50%' : '100%'}>
            <Typography align={bodyTextAlign}>{bodyText}</Typography>
          </Box>
        )}
        {imageRight && (
          <Box width={isMd ? '50%' : null}>
            <Image
              width={isMd ? '600px' : '300px'}
              height={isMd ? '600px' : '300px'}
              objectFit="contain"
              src={imageRight}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

import React from 'react'
import ImageGallery from '@/src/components/views/productPage/ImageGallery'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function GoodfriendsImageQuote({ settings }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const height = settings?.height || '350px'
  const quote = settings?.quote 
  const backgroundImage = settings?.image

  return (
    <Box
      height={height}
      display="flex"
      alignItems="flex-end"
      sx={{
        backgroundColor: '#013C75',
        backgroundSize: 'cover',
        // backgroundAttachment: 'fixed',
        backgroundImage: `url("${backgroundImage}")`,
      }}
    >
      <Typography
        variant="h4"
        align="center"
        sx={{
          width:"100%",
          fontWeight: 500,
          fontStyle: 'italic',
          marginBottom: 4
        }}
      >
        {quote}
      </Typography>
    </Box>
  )
}

import React from 'react';
import PropTypes from 'prop-types';

import {  IconButton, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const IncrementationTextField = ({size, value , min, max, onValueChange}) => {
  const createButton = (icon,side, onClick) => {
    const properties = side === 'left'
      ? {
        borderTopLeftRadius: '0.3rem',
        borderBottomLeftRadius: '0.3rem',
      }
      : {
        borderTopRightRadius: '0.3rem',
        borderBottomRightRadius: '0.3rem',
      };
    return (
      <IconButton
        onClick={onClick}
        
        sx={{
          background: 'linear-gradient(to bottom,#f7f8fa,#e7e9ec)',
          borderColor: '#ADB1B8 #A2A6AC #8D9096',
          borderStyle: 'solid',
          borderWidth: '0.1rem',
          color: 'black',
          borderRadius: 0,
          //height: size === 'small' ? '23px' :'34px',
          fontFamily: 'Recoleta',

          ...properties,
        }}
      >
        {icon}
      </IconButton>
    );
  };
  const createArray = (from, to) => {
    const a = [];
    for (let index = Math.max(from, 0); index <= to; index++) {
      a.push(index);
    }
    return a;
  };

  const onMinusClick = () => {
    if (value > Math.max(0,min)) {
      onValueChange && onValueChange(value - 1);
    }
  };
  const onPlusClick = () => {
    if (max && value >= max){
      window.alert(`${max} is maximum per order`)
    }
    if (!max || value < max) {
      onValueChange && onValueChange(value + 1);
    }
  };
  return (
    <Box display="flex" flexDirection={'row'}>
      {createButton(<RemoveIcon />, 'left', onMinusClick)}
      <Select
        value={value || 0}
        sx={{ fontWeight: 500, borderRadius: 0 }}
        IconComponent={() => null}
        SelectDisplayProps={{
          style: {
            padding: '0 22px',
          },
        }}
        onChange={(event) => onValueChange && onValueChange(event.target.value)}
      >
        {createArray(min, max || 12).map((v) => (
          <MenuItem key={v} value={v}>
            {v}
          </MenuItem>
        ))}
      </Select>
      {createButton(<AddIcon />, 'right', onPlusClick)}
    </Box>
  )
};

IncrementationTextField.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onValueChange: PropTypes.func,
};
export default IncrementationTextField;
import React from 'react'
import ImageGallery from '@/src/components/views/productPage/ImageGallery'
import { Box, Button, Typography, Alert, Link as MuiLink } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'next/image';
import Link from 'next/link'
import { GOODFRIENDS_CA_LINK } from "@/src/utils/urlGenerator";

export default function GoodfriendsHero({ settings }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const title = settings?.title
  const subtitle = settings?.subtitle
  const body = settings?.body
  const productImage = settings?.product_image
  const backgroundImage = settings?.background_image
  const height = settings?.height
  const ctaLink = settings?.cta_link


  return (
    <>
      <Alert severity="warning" icon={false} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ fontWeight: 'bold' }}>
          Shipping to California?
          <Link href={GOODFRIENDS_CA_LINK}>
            <a>
              <MuiLink component={'span'}> click here</MuiLink>
            </a>
          </Link>
        </Typography>
      </Alert>
      <Box
        minHeight={height ? undefined : 'calc(80vh - 60px)'}
        height={height || 'auto'}
        width={'100%'}
        display="flex"
        flexDirection={isMd ? 'row' : 'column'}
        padding={4}
        justifyContent={isMd ? 'space-evenly' : ''}
        alignContent={isMd ? '' : 'center'}
        sx={{
          backgroundColor: '#013C75',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundImage: `url("${backgroundImage}")`,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ fontFamily: 'Hatton', textAlign: !isMd && 'center' }}
        >
          <Typography variant={isMd ? 'h1' : 'h2'}>{title}</Typography>
          <Typography variant="h3" color="textSecondary">
            {subtitle}
          </Typography>
          <Typography variant="h5">{body}</Typography>
          {ctaLink && (
            <Box
              marginTop={7}
              marginBottom={4}
              display="flex"
              justifyContent={!isMd && 'center'}
            >
              <Link href={ctaLink}>
                <a>
                  <Button variant="contained" style={{ fontSize: '20px' }}>
                    BUY NOW
                  </Button>
                </a>
              </Link>
            </Box>
          )}
        </Box>
        <Image
          width={isMd ? '350px' : '100%'}
          height={isMd ? '580px' : '300px'}
          objectFit="contain"
          src={productImage}
        />
      </Box>
    </>
  )
}

import React from 'react'
import ProductCardBigImage from './ProductCardBigImage'
import { useSite } from '@/src/contexts/SiteContext'
import { ProductCardSipjoy , ProductCardRefresh} from '.'

const ProductCard = ({ product, locRef, showSourceInsteadDetails, productCard }) => {
  const { site } = useSite()
  const brandTemplate = site?.brand_template
  const cardType = productCard || brandTemplate?.product_card

  switch (cardType) {
    case 'Refresh':
      return <ProductCardRefresh product={product} locRef={locRef} />
    case 'Big image':
      return <ProductCardBigImage product={product} locRef={locRef} />

    default:
      return (
        <ProductCardSipjoy
          product={product}
          locRef={locRef}
          showSourceInsteadDetails={showSourceInsteadDetails}
        />
      )
  }
}

export default ProductCard

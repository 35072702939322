import React from 'react'
import ImageGallery from '@/src/components/views/productPage/ImageGallery'
import { Box, Button, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'next/image';

export default function GoodfriendsPoints({ settings }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const title = settings?.title
  const height = settings?.height
  const fontSize = settings?.font_size
  const textColor = settings?.text_color
  const pointBackgroundImage = settings?.point_background_image
  const pointBackgroundColor = settings?.point_background_color
  const items = settings?.items || []
  const pointSize = settings?.point_size || '300px'
  const pointPadding = settings?.point_padding || 5

  const sxPointBox = pointBackgroundImage
    ? {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url("${pointBackgroundImage}")`,
    } : {}
  if (pointBackgroundColor) {
    sxPointBox.backgroundColor = pointBackgroundColor
  }

  const itemsComp = []
  for (const item of items) {
    const text = item?.text
    const image = item?.image
    itemsComp.push(
      <Box
        key={text}
        display="flex"
        width="100%"
        justifyContent="center"
        sx={{
          backgroundSize: 'cover',
          backgroundImage: `url("${image}")`,
        }}
      >
        <Box
          margin={5}
          height={pointSize}
          width={pointSize}
          display="flex"
          alignItems="center"
          sx={sxPointBox}
        >
          <Typography
            variant={fontSize ? null : 'h5'}
            align="center"
            sx={{ padding: pointPadding, fontSize, color: textColor }}
          >
            {text}
          </Typography>
        </Box>
      </Box>,
    )
  }

  
  return (
    <Box
      minHeight={height ? undefined : 'calc(80vh - 60px)'}
      height={isMd ? (height || 'auto') : 'auto'}
      width={'100%'}
    >
      {title ? (
        <Box marginTop={10} marginBottom={2}>
          <Typography variant="h2" align="center" sx={{ fontWeight: 400 }}>
            {title}
          </Typography>
        </Box>
      ) : null}
      <Box display="flex" flexDirection={isMd ? 'row' : 'column'}>
        {itemsComp}
      </Box>
    </Box>
  )
}

import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import Image from 'next/image'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import BeerCanChickenSection1Mobile from './BeerCanChickenSection1Mobile'
import DateCountdown from '@/src/components/DateCountdown'
import Link from 'next/link'

export default function BeerCanChickenSection1({ settings }) {
  const soldOut = settings?.soldOut
  const comingSoon = settings?.comingSoon
  const comingSoonEndDate = settings?.comingSoonEndDate
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  let subheadCopy, bodyCopy, bodyCopyColor
  if (soldOut) {
    subheadCopy = 'SOLD OUT'
    bodyCopy =
      'We’re sorry PERDUE® Beer Can Chicken Beer has sold out… for now.'
    bodyCopyColor = '#FFFFFF'
  } else if (comingSoon) {
    subheadCopy = comingSoonEndDate
      ? 'On sale starting in'
      : 'On sale starting on Monday, May 22nd 9am est'
    bodyCopy = comingSoonEndDate && 'Monday, May 22nd 9am est'
  } else {
    subheadCopy = '$14.99* / Six-Pack'
    bodyCopy = '*plus shipping and handling'
  }
  const showBuyBtn = !soldOut && !comingSoon

  if (!isMd) {
    return (
      <BeerCanChickenSection1Mobile
        settings={settings}
        subheadCopy={subheadCopy}
        bodyCopy={bodyCopy}
      />
    )
  }

  return (
    <Box
      display="flex"
      flexDirection={isMd ? 'row' : 'column'}
      padding={4}
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: '#013C75',
        backgroundSize: 'fit',
        backgroundRepeat: 'repeat-x',
        backgroundImage:
          'url("https://res.cloudinary.com/dvcipe8td/image/upload/v1684088307/Beer_Can_Chicken_hero_bg_8f228077f3.png")',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
      >
        <Image
          width="500px"
          height="400px"
          objectFit="contain"
          src="https://res.cloudinary.com/dvcipe8td/image/upload/v1684081811/hero_text_transparent_6961f43afd.png"
        />
        <Box
          display="flex"
          flexDirection={isMd ? 'row' : 'column'}
          gap={2}
          marginLeft="60px"
          width="100%"
          maxWidth="480px"
        >
          <Box>
            <Typography variant="h5" color="#00A6E6">
              {subheadCopy}
            </Typography>
            {comingSoonEndDate && (
              <Box marginRight={6} width="400px">
                <DateCountdown endDate={comingSoonEndDate} />
              </Box>
            )}
            <Typography variant="caption" color={bodyCopyColor || "gray"}>
              {bodyCopy}
            </Typography>
          </Box>
          {showBuyBtn && (
            <Box>
              <Link href="/checkout/beercanchicken">
                <a>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      width: '150px',
                      color: '#013C75',
                      backgroundColor: '#00A6E6',
                    }}
                  >
                    BUY
                  </Button>
                </a>
              </Link>
            </Box>
          )}
        </Box>
      </Box>
      <Image
        width="298px"
        height={isMd ? '500px' : '300px'}
        objectFit="contain"
        src="https://res.cloudinary.com/dvcipe8td/image/upload/v1684082470/can_a926db2dcd.png"
      />
    </Box>
  )
}

import React from 'react'
import { useTheme } from '@mui/material/styles'
import {
  getMinPricePerBottle,
  getPriceSavingPerBottle,
  priceInCentsToStrWithSymbol,
} from '@/src/utils/priceCalc'
import { Box, Typography } from '@mui/material'
import AddToCartButton from './AddToCartButton'
import Image from 'next/image'
import { makeProductUrl } from '@/src/utils/urlGenerator'
import Link from 'next/link'
import useMediaQuery from '@mui/material/useMediaQuery'

const GENERIC_IMAGE = 'https://d2jhwrwze70f0p.cloudfront.net/static/products/generic_wine_bottle.png';

const ProductCardBigImage = ({ product, locRef }) => {
  const theme = useTheme()
  let minPriceInCents = getMinPricePerBottle(product)
  const { maxPrice } = getPriceSavingPerBottle(product, minPriceInCents)

  const productImage = product?.content?.main_image || GENERIC_IMAGE
  const productUrl = makeProductUrl({ product, ref: locRef })
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Link href={productUrl}>
        <a>
          <Box
            display="flex"
            position="relative"
            justifyContent="center"
            sx={{
              height: isMd ? '500px' : '300px',
              width: '100%',
              minWidth: isMd ? '400px' : '200px',
              backgroundColor: theme.palette.background.level2,
              '&:hover': {
                boxShadow: 2,
                // border: `1px solid ${theme.palette.primary.main}`,
                // transform: `translateY(-${theme.spacing(1 / 2)})`,
              },
            }}
          >
            <Image layout="fill" objectFit="cover" src={productImage} />
          </Box>
        </a>
      </Link>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        gap={1}
      >
        <Typography component="span" variant="h5">
          {product.content?.title}
        </Typography>
        {minPriceInCents || product?.soldOut ? (
          <>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              gap={'2px'}
            >
              <Box marginRight="2px">
                {maxPrice && maxPrice > minPriceInCents && !product?.soldOut && (
                  <Typography
                    component="span"
                    color="text.secondary"
                    variant="h6"
                    sx={{
                      marginRight: '5px',
                      textDecoration: 'line-through',
                    }}
                  >
                    {priceInCentsToStrWithSymbol(maxPrice)}
                  </Typography>
                )}
                {minPriceInCents && (
                  <Typography variant="h5" component="span">
                    {` ${priceInCentsToStrWithSymbol(minPriceInCents)}`}
                  </Typography>
                )}
              </Box>
              <Box sx={{}}>
                <AddToCartButton product={product} />
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default ProductCardBigImage

import React from 'react'
import ImageGallery from '@/src/components/views/productPage/ImageGallery'
import { Box, Button, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Image from 'next/image'
import Link from 'next/link'

export default function GoodfriendsHeroSecondary({ settings }) {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const title = settings?.title
  const image = settings?.image
  const backgroundImage = settings?.background_image
  const height = settings?.height
  const ctaText = settings?.cta_text || "BUY NOW"
  const ctaLink = settings?.cta_link


  return (
    <Box
      minHeight={height ? undefined : 'calc(80vh - 60px)'}
      height={height || 'auto'}
      width={'100%'}
      padding={4}
      sx={
        backgroundImage && {
          backgroundColor: '#013C75',
          backgroundSize: 'cover',
          // backgroundAttachment: 'fixed',
          backgroundImage: `url("${backgroundImage}")`,
        }
      }
    >
      <Typography variant={'h2'} sx={{ fontWeight: 400 }} align="center">
        {title}
      </Typography>

      <Box
        display="flex"
        marginTop={3}
        flexDirection={isMd ? 'row' : 'column'}
        width={'100%'}
        // justifyContent="stretch"
        alignItems="center"
        // alignContent="stretch"
      >
        <Box width={isMd ? '50%' : null}>
          <Image
            width={isMd ? '600px' : '300px'}
            height={isMd ? '600px' : '300px'}
            objectFit="contain"
            src={image}
          />
        </Box>
        {ctaLink && (
          <Box
            display="flex"
            justifyContent={isMd ? 'flex-start' : 'center'}
            width={isMd ? '50%' : '100%'}
          >
            <Link href={ctaLink}>
              <a>
                <Button
                  size="large"
                  variant="contained"
                  style={{ fontSize: '30px' }}
                  sx={{
                    marginTop: isMd ? 15 : 5,
                    marginLeft: isMd && 25,
                    height: '80px',
                    fontSize: isMd ? '50px' : 18,
                    padding: '15px 50px',
                  }}
                >
                  {ctaText}
                </Button>
              </a>
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  )
}


import React, {useState} from 'react'

import PropTypes from 'prop-types';
import { Cart } from '@/utils/cart';
import { Badge, Button, Fab ,ButtonGroup, IconButton, Typography} from '@mui/material';
import styles from './ProductCard.module.css'
import { Mixpanel } from '@/utils/mixpanel';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';


import { useRouter } from "next/router";
import { useTheme } from '@mui/material/styles';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ProductTransactionDetails from '../views/checkOutPage/components/TransactionDetails/ProductTransactionDetails';
import { LoadingButton } from '@mui/lab';
function AddToCartButton({product}) {

  const theme = useTheme();
  const router = useRouter()
  const [loading, setLoading] = useState();
  const [amount, setAmount] = useState(() => Cart.getProductAmount({product}))
  

  const onAmountChange= (newAmount) => {
    Mixpanel.track('Cart amount change from card', {newAmount})
    Cart.updateProductAmount({ product, quantity: newAmount })
    setAmount(newAmount)
  }
  if (product.soldOut) {
    return (
      <Button
        size="small"
        variant="contained"
        disabled={true}
        sx={{ borderRadius: 3 }}
      >
        Sold out
      </Button>
    )
  }

  const navigateToCheckout = true;
  if (navigateToCheckout) {
    
    return (
      <LoadingButton
        loading={loading}
        onClick={() => {
          setLoading(true)
          const { checkoutUrl } = Cart.updateProductAmount({
            product,
            quantity: 1,
          })
          router.push(checkoutUrl)
        }}
        variant="contained"
        size="large"
        fullWidth
      >
        Buy Now
      </LoadingButton>
    )
  }
  
  return amount ? (
    <ButtonGroup size="small" variant="contained" sx={{ borderRadius: 3 }}>
      <Button onClick={() => onAmountChange(amount - 1)}>
        {amount === 1 ? <DeleteOutlineIcon /> : <RemoveIcon />}
      </Button>
      <Typography variant="h6" sx={{ margin: '2px 8px' }}>
        {amount}
      </Typography>
      <Button onClick={() => onAmountChange(amount + 1)}>
        <AddIcon />
      </Button>
    </ButtonGroup>
  ) : (
    // <Fab variant="extended" color="primary" size="small" aria-label="add">
    //   <AddIcon />
    //   Add to Cart
    // </Fab>
    <Button
      size="small"
      variant="contained"
      sx={{ borderRadius: 3 }}
      startIcon={
        <AddShoppingCartIcon />
        //   // <Badge  badgeContent={<AddIcon fontSize="small" />}>
        //   //   <ShoppingCartOutlinedIcon sx={{marginRight:"7px"}} />
        //   // </Badge>
      }
      onClick={() => onAmountChange(1)}
    >
      Add to cart
    </Button>
  )
}

AddToCartButton.propTypes = {
  product: PropTypes.object.isRequired,
};
export default AddToCartButton;



import React from 'react';

import PropTypes from 'prop-types';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import IncrementationTextField from '@/components/IncrementationTextField';
import { MAX_NUM_BOTTLES_PER_ORDER } from '@/utils/priceCalc';
import Image from 'next/image'

const ProductTransactionDetails = ({
  product,
  includeBrandName,
  transactionPriceInfo,
  allowAmountChange = true,
  onAmountChange,
}) => {
  const minBottles = 1;
  const maxBottles = parseInt(product?.purchaseInfo?.max_amount_per_order || MAX_NUM_BOTTLES_PER_ORDER);
  const image = product?.content?.main_image;
  const amount =
    transactionPriceInfo?.amount && parseInt(transactionPriceInfo.amount);
  const priceBeforeDiscount =
    transactionPriceInfo?.productPriceBeforeDiscountStr;
  const price = transactionPriceInfo?.productPriceStr;
  
  const packSingular = product?.package_type?.singular || '1 bottle'
  const packPlural = product?.package_type?.plural || 'bottles';

  let priceComp, priceChangeComp, nameComp;
  if (price) {
    priceComp = priceBeforeDiscount ? (
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textDecoration: 'line-through' }}
        >
          {priceBeforeDiscount}
        </Typography>

        <Typography sx={{ fontWeight: 600, fontSize: '1.2rem' }}>
          {price}
        </Typography>
      </Box>
    ) : (
      <Typography sx={{ fontWeight: 600, fontSize: '1.2rem' }}>
        {price}
      </Typography>
    );
    priceChangeComp = price ? (
      <Box display="flex" flexDirection={'row'} gap={2} sx={{ marginLeft: 2 }}>
        {allowAmountChange ? (
          <>
            <IncrementationTextField
              value={amount}
              min={minBottles}
              max={maxBottles}
              onValueChange={onAmountChange}
            />
            <Button
              color="error"
              size="small"
              variant="outlined"
              onClick={() => onAmountChange(0)}
            >
              Delete
            </Button>
          </>
        ) : (
          <Typography>
            {amount === 1 ? packSingular : `${amount} ${packPlural}`}
          </Typography>
        )}
      </Box>
    ) : (
      <Typography variant="subtitle2" sx={{ color: 'red' }}>
        Not available
      </Typography>
    )
  }

  nameComp = (
    <Box>
      {includeBrandName && product.brand?.name && (
        <Typography
          variant="body2"
          sx={{ fontWeight: 600, textDecoration: 'underline' }}
        >
          {product.brand.name}
        </Typography>
      )}
      <Typography>{product.content?.title}</Typography>
    </Box>
  );
  return (
    <Box
      display="flex"
      flexDirection={'column'}
      gap={1}
      sx={{ marginBottom: 2 }}
    >
      <Box display="flex" flexDirection={'row'} gap={1}>
        {image && (
          <CardMedia>
            <Image
              width="100px"
              height="100px"
              objectFit={'contain'}
              src={image}
            />
          </CardMedia>
        )}
        <Box
          marginBottom="5px"
          display="flex"
          flexDirection={'column'}
          gap={1}
          justifyContent="space-between"
        >
          {nameComp}
          {priceComp}
        </Box>
      </Box>
      {priceChangeComp}
    </Box>
  )
};



export default ProductTransactionDetails;
import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Card, Typography, Box, Chip, Link as MuiLink, CardHeader, CardMedia } from '@mui/material'
import styles from './ProductCard.module.css'
import { getProducerLocationStr } from '@/utils/location'
import {
  getMinPricePerBottle,
  priceInCentsToStrWithSymbol,
  applyDiscountOnPrice,
  getPriceSavingPerBottle,
} from '@/utils/priceCalc'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import ProductChips from '../ProductChips'
import { makeProductUrl } from '@/utils/urlGenerator'
import MarkdownView from '../Markdown/MarkdownView'
import AddToCartButton from './AddToCartButton'
const GENERIC_IMAGE = 'https://d2jhwrwze70f0p.cloudfront.net/static/products/generic_wine_bottle.png';

const ProductCardSipjoy = ({
  product,
  locRef,
  showProducer = true,
  showSourceInsteadDetails,
  discountPercentage,
}) => {
  const theme = useTheme()

  const [image, setImage] = useState()

  useEffect(() => {
    const url = product?.content?.main_image || GENERIC_IMAGE;
    // we use the cloudfront domain instead of the s3
    setImage (
      url &&
      url.replace(
        'https://sipjoy-public.s3.amazonaws.com/',
        'https://d2jhwrwze70f0p.cloudfront.net/',
      )
    )
  }, [product?.key]);

  const priceColor = theme.palette.primary.main
  const productUrl = makeProductUrl({ product, ref: locRef })
  const brand = product.brand;
  const type = product.content?.type;

  let cardContentRight
  if (showSourceInsteadDetails) {
    const locationStr = getProducerLocationStr(brand, {countryFirst: true})
    cardContentRight = brand && (
      <>
        <Box>
          {showProducer && brand?.slug && (
            <MuiLink
              underline="hover"
              href={`/producer/${brand.slug}?ref=product_card`}
            >
              <Typography component="span" color={theme.palette.text.primary}>
                {brand.name}
              </Typography>
            </MuiLink>
          )}
          {type && (
            <Typography variant="title1" gutterBottom component="span">
              {' | ' +
                [
                  type.category,
                  type.wine_type,
                  type.spirit_type,
                  type.spirit_sub_type,
                ]
                  .filter((v) => v)
                  .join(' | ')}
            </Typography>
          )}
          {locationStr && (
            <Typography variant="title1" component="span">
              {` | ${locationStr}`}
            </Typography>
          )}
          {type.grape && (
            <Typography
              variant="body2"
              gutterBottom
              color={'text.secondary'}
              sx={{ marginTop: 1 }}
            >
              Grape: {type.grape}
            </Typography>
          )}
        </Box>
      </>
    )
  } else {
    cardContentRight = (
      <MarkdownView>
        {product?.content?.short_description}
      </MarkdownView>
    )
  }

  let minPriceInCents = getMinPricePerBottle(product)
  const { maxPrice } = getPriceSavingPerBottle(product, minPriceInCents)
  
  if (discountPercentage || product.discountPercentage) {
    minPriceInCents = applyDiscountOnPrice(
      minPriceInCents,
      discountPercentage || product.discountPercentage,
    )
  }

  const awards = product?.awards ? (
    <Box display="flex" flexDirection="row" gap={1}>
      {product.awards.map((award, i) => (
        <Chip
          key={i}
          size="small"
          color="primary"
          icon={<EmojiEventsIcon />}
          label={award.displayText}
        />
      ))}
    </Box>
  ) : (
    []
  )

  const isPopular =
    product.popularity?.numOfPastOrders6Months > 2 ||
    (product.features?.searchableTags || []).includes('Popular')
  const ribbonText = isPopular && 'Popular'
  return (
    <Card
      sx={{
        position: 'relative',
        height: '100%',
        borderRadius: '20px',
        transition: 'all .2s ease-in-out',
        '&:hover': {
          transform: `translateY(-${theme.spacing(1 / 2)})`,
        },
      }}
    >
      {ribbonText && (
        <Box position="absolute" sx={{ right: 0 }}>
          <Box
            position="relative"
            sx={{
              right: 0,
              margin: '50px -2px',
              padding: '5px 8px',
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              boxShadow: '2px 2px 2px #695b5fde',
              backgroundColor: theme.palette.primary.main,
            }}
            zIndex={2}
          >
            <Typography
              variant="body1"
              color="#ffffff"
              sx={{ fontWeight: 700 }}
            >
              {ribbonText}
            </Typography>
          </Box>
        </Box>
      )}

      <CardHeader
        sx={{ padding: 1 }}
        title={
          <MuiLink href={productUrl}>
            <Typography
              variant="h6"
              component="div"
              align="center"
              color={priceColor}
              sx={{
                fontWeight: '600',
              }}
            >
              {product.content?.title}
            </Typography>
          </MuiLink>
        }
      />

      <MuiLink href={productUrl} component="a" sx={{ cursor: 'pointer' }}>
        <CardMedia
          component="img"
          height="194"
          image={image}
          alt="product image"
          onError={() =>
            setImage(GENERIC_IMAGE)
          }
          sx={{ objectFit: 'contain', padding: '5px' }}
        />
      </MuiLink>
      <Box
        display="flex"
        flexDirection={'column'}
        justifyContent={'space-between'}
        className={styles.mainContent}
        gap="5px"
        padding="10px 10px 45px 10px"
      >
        <Box display="flex" gap={1} flexWrap="wrap" justifyContent="center ">
          <ProductChips producer={brand} product={product} />
        </Box>
        {awards}

        {product.hidden && (
          <Typography
            gutterBottom
            align="center"
            sx={{
              color: 'red',
              fontWeight: 'medium',
              borderBottom: 'dotted red',
              borderTop: 'dotted red',
              paddingBottom: '2px',
              marginRight: '20px',
            }}
          >
            hidden on main site
          </Typography>
        )}

        {cardContentRight}
      </Box>

      <Box sx={{ position: 'absolute', bottom: 10, left: 10 }}>
        {maxPrice && maxPrice > minPriceInCents && !product?.soldOut && (
          <Typography
            component="span"
            color="text.secondary"
            variant="h6"
            sx={{
              textDecoration: 'line-through',
            }}
          >
            {priceInCentsToStrWithSymbol(maxPrice)}
          </Typography>
        )}
        {minPriceInCents && (
          <Typography
            variant="h6"
            component="span"
          >
            {` ${priceInCentsToStrWithSymbol(minPriceInCents)}`}
          </Typography>
        )}
      </Box>
      {minPriceInCents || product?.soldOut ? (
        <Box sx={{ position: 'absolute', bottom: 10, right: 10 }}>
          <AddToCartButton product={product} />
        </Box>
      ) : null}
    </Card>
    // </Box>
  )
}

ProductCardSipjoy.propTypes = {
  locRef: PropTypes.string.isRequired,
  showProducer: PropTypes.string,
  showSourceInsteadDetails: PropTypes.bool,
  discountPercentage: PropTypes.number,
  product: PropTypes.object.isRequired,
}

export default ProductCardSipjoy


import React from 'react';
import BeerCanChickenCheckoutHeader from './BeerCanChicken/BeerCanChickenCheckoutHeader';
import BeerCanChickenSection1 from './BeerCanChicken/BeerCanChickenSection1';
import BeerCanChickenSection2 from './BeerCanChicken/BeerCanChickenSection2';
import BeerCanChickenSection3 from './BeerCanChicken/BeerCanChickenSection3';
import BeerCanChickenSection4 from './BeerCanChicken/BeerCanChickenSection4';
import BeerCanChickenVideo from './BeerCanChicken/BeerCanChickenVideo';
import GoodfriendsHero from './Goodfriends/GoodfriendsHero';
import GoodfriendsText from './Goodfriends/GoodfriendsText';
import GoodfriendsPoints from './Goodfriends/GoodfriendsPoints';
import GoodfriendsImageQuote from './Goodfriends/GoodfriendsImageQuote';
import GoodfriendsHeroSecondary from './Goodfriends/GoodfriendsHeroSecondary';
import GradientetHero from './General/GradientetHero';
import CuratedLinks from './General/CuratedLinks';
import MediaWithText from './General/MediaWithText';
import Banner from './General/Banner';
import Products from './General/Products';


export default function PredefinedComponent({name, settings}) {
  if (!name) {
    return null;
  }
  switch (name) {
    case 'Products':
      return <Products settings={settings} />
    case 'Banner':
      return <Banner settings={settings} />
    case 'GradientetHero':
      return <GradientetHero settings={settings} />
    case 'CuratedLinks':
      return <CuratedLinks settings={settings} />
    case 'MediaWithText':
      return <MediaWithText settings={settings} />
    case 'Goodfriends - Hero':
      return <GoodfriendsHero settings={settings} />
    case 'Goodfriends - Hero Secondary':
      return <GoodfriendsHeroSecondary settings={settings} />
    case 'Goodfriends - Text':
      return <GoodfriendsText settings={settings} />
    case 'Goodfriends - Points':
      return <GoodfriendsPoints settings={settings} />
    case "Goodfriends - Image with quote":
      return <GoodfriendsImageQuote settings={settings}/>
    case 'BeerCanChicken - Checkout Header':
      return <BeerCanChickenCheckoutHeader settings={settings} />
    case 'BeerCanChicken - Video':
      return <BeerCanChickenVideo settings={settings} />
    case 'BeerCanChicken - Section1':
      return <BeerCanChickenSection1 settings={settings} />
    case 'BeerCanChicken - Section2':
      return <BeerCanChickenSection2 settings={settings} />
    case 'BeerCanChicken - Section3':
      return <BeerCanChickenSection3 settings={settings} />
    case 'BeerCanChicken - Section4':
      return <BeerCanChickenSection4 settings={settings} />

    default:
      return null
  }
}
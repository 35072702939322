import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Image from 'next/image'
import Link from 'next/link'

export default function CuratedLink({ title, image, link }) {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <Link href={link}>
      <a>
        <Box display="flex" flexDirection="column" alignContent="center">
          {image && (
            <Image
              width="200px"
              height="150px"
              objectFit="cover"
              src={image}
            />
          )}
          <Typography align="center">{title}</Typography>
        </Box>
      </a>
    </Link>
  )
}

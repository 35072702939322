import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function GoodfriendsText({ settings }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const title = settings?.title 
  const body = settings?.body 
  const backgroundColor = settings?.background_color || undefined

  return (
    <Box
      padding={6}
      display="flex"
      gap={5}
      flexDirection={isMd ? 'row' : 'column'}
      sx={{
        backgroundColor
      }}
    >
      <Typography
        align={!isMd && 'center'}
        variant="h2"
        align="center"
        component={Box}
        sx={{ width: '100%' }}
      >
        {title}
      </Typography>
      <Typography>{body}</Typography>
    </Box>
  )
}

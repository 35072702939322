import { Typography, Box } from '@mui/material';
import React from 'react';
import Container from '@/src/common/Container';
import MarkdownView from '../Markdown/MarkdownView';
import Image from 'next/image';
import ImageHeroCard from './ImageHeroCard';
import PredefinedComponent from '../SitePage/PredefinedPageComponents/PredefinedComponent';

const ImageHero = ({
  title,
  subtitle,
  heroImg,
  card,
  height,
  predefinedComponent,
  marginTop = '-10px',
}) => {
  const useGardient = title || subtitle
  const afterSx = useGardient && {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    content: '" "',
    zIndex: 1,
    backgroundImage: 'linear-gradient(black, #ffffff00)',
    backgroundAttachment: 'fixed',
    opacity: 0.8,
  }

  if (predefinedComponent) {
    return (
      <PredefinedComponent
        name={predefinedComponent.name}
        settings={predefinedComponent.settings}
      />
    )
  }

  let cardComp
  if (card) {
    cardComp = (
      <ImageHeroCard
        title={card.title}
        subtitle={card.subtitle}
        cta={card.cta}
        onClick={card.onClick}
      />
    )
  }

  return (
    <Box
      // minHeight={{ xs: 'auto', md: 'calc(80vh - 60px)' }}
      minHeight={height ? undefined : 'calc(80vh - 60px)'}
      height={height || 'auto'}
      width={'100%'}
      position={'relative'}
      sx={{
        marginTop,
        '&:after': afterSx,
      }}
    >
      <Box sx={{ zIndex: -1 }}>
        <Image
          priority
          src={heroImg?.src}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          alt="hero image"
        />

        <Box
          position={{ xs: 'relative', md: 'absolute' }}
          top={{ xs: 0, md: '50%' }}
          width={'100%'}
          height={'auto'}
          sx={{
            transform: { md: 'translateY(-50%)' },
          }}
          zIndex={2}
        >
          <Container>
            {cardComp}
            {title && (
              <Typography
                variant="h2"
                gutterBottom
                align="center"
                sx={{
                  color: 'white',
                  fontFamily: 'MariaConnected',
                  fontWeight: 700,
                }}
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                sx={{ color: 'white', fontFamily: 'MariaConnected' }}
              >
                <MarkdownView>{subtitle}</MarkdownView>
              </Box>
            )}
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

export default ImageHero;

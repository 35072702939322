

const countryToIcon = {
  'Spain' : '🇪🇸',
  'Argentina' : '🇦🇷',
  'Portugal' : '🇵🇹',
  'USA' : '🇺🇸',
  'United States' : '🇺🇸',
  'United Kingdom' : '🇬🇧',
  'France' : '🇫🇷',
  'Israel' : '🇮🇱',
  'Italy' : '🇮🇹',
  'Australia' : '🇦🇺',
  'Chile' : '🇨🇱',
  'South Africa' : '🇿🇦',
  'New Zealand' : '🇳🇿',
  'Germany' : '🇩🇪',
  'Austria' : '🇦🇹',
  'Canada' : '🇨🇦',
  'Greece' : '🇬🇷',
  'Japan' : '🇯🇵',
  'China' : '🇨🇳',
  'Peru' : '🇵🇪',
  'Moldova' : '🇲🇩',
  'Uruguay' : '🇺🇾',
  'Mexico' : '🇲🇽',
  'Scotland' : '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
  'Ireland' : '🇮🇪',
  'Russia' : '🇷🇺',
  'Netherlands' : '🇳🇱',
  'Sweden' : '🇸🇪',
  'Latvia' : '🇱🇻',
  'Korea' : '🇰🇷',
  'Poland' : '🇵🇱',
  'Puerto Rico' : '🇵🇷',
  'Georgia' : '🇬🇪',

};
function addIconToCountry(countryName) {
  const icon = countryToIcon[countryName];
  return icon ? `${icon} ${countryName}` : countryName;
}
function getProducerLocationStr(producer, attr) {
  const {countryFirst } = attr || {};
  const country =
    producer?.location?.country 
      ? producer.location.country
      : 'USA';
  let locationStr = [producer?.location?.region, producer?.location?.state, country]
    .filter((a) => a)
    .join(', ');
  if (country && countryToIcon[country]) {
    locationStr = countryFirst
      ? `${countryToIcon[country]} ${locationStr}`  
      : locationStr + ` ${countryToIcon[country]}`
  }
  return locationStr;
}


module.exports = {
  countryToIcon,
  addIconToCountry,
  getProducerLocationStr
};
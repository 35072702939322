import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ReactPlayer from 'react-player'
import NarrowContainer from '@/src/common/NarrowContainer'

export default function BeerCanChickenVideo({ settings }) {
  const video = settings?.video
  if (!video) {
    return null
  }
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })


  return (
    <NarrowContainer
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        padding: 2,
      }}
      width={'100%'}
    >
      <ReactPlayer
        height={isMd ? '500px' : '200px'}
        width={'100%'}
        playing={true}
        loop={true}
        controls={true}
        muted={true}
        url={video}
        fileConfig={{ attributes: { playsinline: true } }}
      />
    </NarrowContainer>
  )
}

import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Image from 'next/image'
import ProductCard from '@/src/components/ProductCard'
import { getSiteProductsFromService, getProductFromService } from "@/src/services/sipjoyService"
import { useSite } from '@/src/contexts/SiteContext';
import NarrowContainer from '@/src/common/NarrowContainer'

export default function Products({ settings }) {
  const { site } = useSite()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const backgroundImage = settings?.background_image
  const height = settings?.height
  const cardType = settings?.card_type
  const productKey = settings?.product
  const [products, setProducts] = useState();

  useEffect(async () => {
    let products
    if (productKey) {
      const product = await getProductFromService({
        siteKey: site.key,
        slug: productKey,
      })
      products = [product]
    } else {
      products = await getSiteProductsFromService({ siteKey: site.key })
    }
    setProducts(products)
  }, [])

  const productsCompsArray = products && products.map((p, key)=> {
    return (
      <ProductCard key={key} product={p} productCard={cardType}/>
    )
  })
  return (
    <Box
      height={height || 'auto'}
      width={'100%'}
      padding={4}
      sx={
        backgroundImage && {
          backgroundSize: 'cover',
          // backgroundAttachment: 'fixed',
          backgroundImage: `url("${backgroundImage}")`,
        }
      }
    >
      <NarrowContainer>
        <Box
          display="flex"
          flexDirection={isMd ? 'row' : 'column'}
          justifyContent="center"
          gap={2}
          flexFlow="wrap"
        >
          {productsCompsArray}
        </Box>
      </NarrowContainer>
    </Box>
  )
}

import React from 'react'
import ImageGallery from '@/src/components/views/productPage/ImageGallery'
import { Box, Button, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'next/image';
import Link from 'next/link'

export default function GradientetHero({ settings }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const title = settings?.title 
  const titleColor = settings?.title_color || undefined
  const subtitle = settings?.subtitle
  const subtitleColor = settings?.subtitle_color || undefined
  const body = settings?.body
  const bodyColor = settings?.body_color || undefined
  const height = settings?.height
  const ctaLink = settings?.cta_link

  const image = settings?.image
  const colorFrom = settings?.color_from
  
  const colorTo = settings?.color_to
  
  return (
    <Box
      minHeight={height ? height : 'calc(80vh - 60px)'}
      height={'auto'}
      width={'100%'}
      display="flex"
      flexDirection="column"
      padding={4}
      justifyContent={'space-evenly'}
      alignContent={isMd ? '' : 'center'}
      sx={{
        textAlign: 'center',
        height: '100%',
        width: '100%',
        content: '" "',
        zIndex: 1,
        backgroundImage: `linear-gradient(${colorFrom}, ${colorTo})`,
        backgroundAttachment: 'fixed',
        opacity: 0.8,
      }}
    >
      <Box>
        <Typography variant={isMd ? 'h1' : 'h2'} sx={{ color: titleColor }}>
          {title}
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          sx={{ color: subtitleColor }}
        >
          {subtitle}
        </Typography>
        <Typography variant="h5" sx={{ color: bodyColor }}>{body}</Typography>
        {ctaLink && (
          <Box
            marginTop={7}
            marginBottom={4}
            display="flex"
            justifyContent={!isMd && 'center'}
          >
            <Link href={ctaLink}>
              <a>
                <Button variant="contained" style={{ fontSize: '20px' }}>
                  BUY NOW
                </Button>
              </a>
            </Link>
          </Box>
        )}

        {image && (
          <Image
            width={isMd ? '500px' : '100%'}
            height={isMd ? '500px' : '300px'}
            objectFit="contain"
            src={image}
          />
        )}
      </Box>
    </Box>
  )
}

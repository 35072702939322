import React from 'react';

import { Chip } from '@mui/material';
import styles from './ProductChips.module.css';

const MAX_CHARACTERISTICS_TO_DISPLAY = 4;
const ProductChips = ({product, producer}) => {
  
  const createChip = (v, className, variant) => {
    return (
      <Chip className={styles[className]} variant={variant} size="small" key={v} label={v} />
    );
  };

  let characteristics = product?.features?.characteristics || [];
  if (characteristics.length > MAX_CHARACTERISTICS_TO_DISPLAY) {
    characteristics = characteristics.slice(0, MAX_CHARACTERISTICS_TO_DISPLAY)
  }
  const producerFeatures = [...new Set([
    ...(producer?.features?.esg || []),
    ...(producer?.features?.farming || []),
  ])];

  
  const features = [
    product?.features?.weight &&
      createChip(
        product.features.weight,
        `weight_${product.features.weight}`.toLowerCase(),
      ),
    ...characteristics.map((v) => createChip(v)),
    ...(producerFeatures).map((v) => createChip(v,'producer_chip', 'outlined')),
  ];
  return (
    features
  );
};

export default ProductChips;

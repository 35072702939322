import Image from 'next/image';
import React from 'react';

var Carousel = require('react-responsive-carousel').Carousel;

const ImageGallery = ({ images, height, showThumbs = true }) => {
  if (!images) {
    return null
  }
  const imageSections = images.map((url, i) => {
    const u = url.replace('undefined', '')
    return (
      <div key={i} style={{ width: '100%', height }}>
        <Image layout={'fill'} objectFit={'contain'} src={u} />
      </div>
    )
  })

  return (
    <Carousel
      dynamicHeight={true}
      interval={5000}
      autoPlay={true}
      showThumbs={showThumbs}
      showStatus={false}
      infiniteLoop={true}
      renderThumbs={() =>
        images.map((thumbnail) => (
          <Image width="80px" height="80px" src={thumbnail} key={thumbnail} />
        ))
      }
    >
      {imageSections}
    </Carousel>
  )
}

export default ImageGallery;
import { Typography, Box, Card, CardContent, Button } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery';

const ImageHeroCard = ({ title, subtitle, cta, onClick }) => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const mainColor = theme.palette.primary.main
  return (
    <Box
      component={Card}
      borderRadius={'1.8rem'}
      padding={3}
      position={'relative'}
      maxWidth="600px"
      margin={isMd ? '50px' : '100px auto'}
    >
      <Box
        component={CardContent}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={2}
      >
        <Typography
          variant="h3"
          color={mainColor}
          sx={{ fontWeight: 'bold' }}
        >
          {title}
        </Typography>
        <Box
          component={Typography}
          variant={'h6'}
          fontWeight={500}
          align={'left'}
        >
          {subtitle}
        </Box>
        <Button variant="contained" size="large" onClick={onClick}>
          {cta}
        </Button>
      </Box>
    </Box>
  )
}

export default ImageHeroCard

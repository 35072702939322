import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Image from 'next/image'
import NarrowContainer from '@/src/common/NarrowContainer';

export default function Banner({ settings }) {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const text = settings?.text
  const textColor = settings?.text_color

  return (
    <Box
      height={'100%'}
      padding="20px 20px 14px 20px"
      marginTop="-7px"
    >
      <NarrowContainer
        display={'flex'}
        flexDirection={'row'}
        justifyItems={'center'}
        gap={2}
      >
        <Typography
          variant={'h6'}
          color="primary"
          sx={{ fontWeight: 'bold', color: textColor, width: '100%' }}
          align="center"
        >
          {text}
        </Typography>
      </NarrowContainer>
    </Box>
  )
}

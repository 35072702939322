import React from 'react'
import ImageGallery from '@/src/components/views/productPage/ImageGallery'
import { Box, Button, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'next/image';
import Link from 'next/link'
import CuratedLink from './CuratedLink';
import SocialLinks from './SocialLinks';

export default function CuratedLinks({ settings }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const title = settings?.title 
  const titleColor = settings?.title_color || undefined
  const backgroundImage = settings?.backgroundImage
  const socail = settings?.socail
  const items = settings?.items || []
  const itemsComp = []
  for (const item of items) {
    if (item?.link) {
      itemsComp.push(
        <CuratedLink
          key={item.title}
          title={item.title}
          image={item?.image}
          link={item?.link}
        />,
      )
    }
  }
  const mainBoxSx = backgroundImage
    ? {
      backgroundColor: '#013C75',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom left',
      backgroundSize: 'cover',
      // backgroundAttachment: 'fixed',
      backgroundImage: `url("${backgroundImage}")`,
    }
    : {}
  const socailComp = socail && (
    <SocialLinks settings={socail}/>
  )
  return (
    <Box
      padding={isMd ? 8 : 4}
      width={'100%'}
      display="flex"
      flexDirection="column"
      sx={mainBoxSx}
      gap={isMd ? 8 : 3}
    >
      <Typography align="center" variant={'h4'} sx={{ color: titleColor }}>
        {title}
      </Typography>
      <Box
        display="flex"
        width={'100%'}
        justifyContent="center"
        flexDirection={isMd ? 'row' : 'column'}
        gap={3}
      >
        {itemsComp}
      </Box>
      <Box sx={{ alignSelf: 'flex-end' }}>{socailComp}</Box>
    </Box>
  )
}

import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import IconButton from '@mui/material/IconButton'

export default function SocialLinks({ settings }) {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const text = settings?.text
  const facebookLink = settings?.facebookLink
  const instagramLink = settings?.instagramLink
  const twitterLink = settings?.twitterLink
  const iconsColor = settings?.iconsColor || theme.palette.secondary.main

  return (
    <Box display="flex" flexDirection={'column'}>
      <Box
        display="flex"
        flexDirection={'row'}
        justifyContent="space-evenly"
        width="100%"
      >
        {instagramLink && (
          <IconButton
            aria-label="Instagram"
            component={'a'}
            href={instagramLink}
            target="_blank"
            sx={{ padding: '0', color: iconsColor }}
          >
            <InstagramIcon />
          </IconButton>
        )}
        {facebookLink && (
          <IconButton
            aria-label="Facebook"
            component={'a'}
            href={facebookLink}
            target="_blank"
            sx={{ padding: '0', color: iconsColor }}
          >
            <FacebookIcon />
          </IconButton>
        )}
        {twitterLink && (
          <IconButton
            aria-label="Twitter"
            component={'a'}
            href={twitterLink}
            target="_blank"
            sx={{ padding: '0', color: iconsColor }}
          >
            <TwitterIcon />
          </IconButton>
        )}
      </Box>
      <Typography align="center">{text}</Typography>
    </Box>
  )
}

import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Button, Typography } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import useMediaQuery from '@mui/material/useMediaQuery'

export default function BeerCanChickenSection3({ settings }) {
  const soldOut = settings?.soldOut
  const comingSoon = settings?.comingSoon 
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  if (soldOut) {
    return null
  }

  const showBuyBtn = !soldOut && !comingSoon
  return (
    <Box display="flex" flexDirection="row" gap={1}>
      {isMd && (
        <Box
          height="650px"
          width="100%"
          sx={{
            // flexGrow: 1,
            paddingLeft: 10,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            backgroundImage:
              'url("https://res.cloudinary.com/dvcipe8td/image/upload/v1683460747/BCCB_Section3_LEFT_icons_ffebc5c928.png")',
          }}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        gap={isMd ? 3 : 1}
        padding={isMd ? '50px 0 50px 0' : '50px 20px'}
        sx={{ textAlign: 'center' }}
      >
        <Image
          width="300px"
          height="150px"
          objectFit="contain"
          src="https://res.cloudinary.com/dvcipe8td/image/upload/v1683460744/BCCB_Section3_title_a861a4dbae.png"
        />
        <Typography color="#00a3e0" variant="h5" fontWeight="600">
          THE FIRST AND ONLY BEER BREWED
          <br />
          SPECIFICALLY FOR BEER CAN CHICKEN
        </Typography>
        <Typography
          color="#ffffff"
          sx={{ fontFamily: 'Helvetica', fontSize: isMd ? '20px' : '17px' }}
        >
          With the help from our friends at Torch & Crown Brewing Company, we’ve
          crafted a honey double-citrus summer ale brewed with classic grilled
          chicken seasonings like rosemary, thyme, and pink peppercorn, to help
          backyard grillers make the perfect beer can chicken with PERDUE® Whole
          Chickens this season. With PERDUE® Beer Can Chicken Beer, you’ll be
          roasting and toasting all summer long!
        </Typography>
        <Typography
          variant="caption"
          color="#c2c2c2"
          sx={{ fontFamily: 'Helvetica', fontSize: isMd ? '17px' : '15px' }}
        >
          Shipping and applicable state taxes will be added, to eligible US
          states only. Must be 21+ to purchase, adult signature required. Limit
          two per customer. All sales are final.
        </Typography>
        {showBuyBtn && (
          <Link href="/checkout/beercanchicken">
            <a>
              <Button variant="contained" color="secondary">
                BUY NOW
              </Button>
            </a>
          </Link>
        )}
      </Box>
      {isMd && (
        <Box
          height="650px"
          width="100%"
          sx={{
            // flexGrow: 2,
            paddingRight: 10,
            backgroundSize: 'cover',
            backgroundImage:
              'url("https://res.cloudinary.com/dvcipe8td/image/upload/v1683460747/BCCB_Section3_RIGHT_icons_0a759483b6.png")',
          }}
        />
      )}
    </Box>
  )
}
